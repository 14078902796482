import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';

import Layout from "../components/Layout";
import Logo from "../components/Logo";

// eslint-disable-next-line
export const ReleasePostTemplate = ({
  title,
  artist,
  artworkimage,
}) => {


  return (
    <>
      <div className="innerhero">
        <Logo />
        <h1>{title}</h1>
        <h2>{artist}</h2>

      </div>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true} >
          <div className="inner">
              {artworkimage}
          </div>
        </ScrollAnimation>
    </>
  );
};

ReleasePostTemplate.propTypes = {
  title: PropTypes.string,
  artist: PropTypes.string,
  artworkimage: PropTypes.string,
};

const ReleasePost = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ReleasePostTemplate
        title={frontmatter.title}
        artist={frontmatter.artist}
        artworkimage={frontmatter.artworkimage}
      />
    </Layout>
  );
};

ReleasePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ReleasePost;

export const pageQuery = graphql`
  query ReleasePostTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "release-post" } }) {
      frontmatter {
        title
        artist
      }
    }
  }
`;
